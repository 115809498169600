/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~leaflet/dist/leaflet.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

/*leaflet-geosearch css */
@import "~leaflet-geosearch/dist/geosearch.css";

/* Font Family */
/* Gilroy-Bold */
@font-face {
  font-family: "Roboto-Regular";
  src: url(assets/fonts/Roboto-Regular.ttf) format("truetype");
}

/* Gilroy-Light */
@font-face {
  font-family: "Roboto-Medium";
  src: url(assets/fonts/Roboto-Medium.ttf) format("truetype");
}

/* Gilroy-Light */
@font-face {
  font-family: "Roboto-Bold";
  src: url(assets/fonts/Roboto-Bold.ttf) format("truetype");
}

html,
body {
  height: 100% !important;
  font-family: "Roboto-Regular", serif, "Times New Roman", Times;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto-Medium", serif, "Times New Roman", Times;
  margin: 0;
  color: #2a4659;
}

.sub-title {
  font-family: "Roboto-Medium", serif, "Times New Roman", Times;
  color: #4a7d9e;
}

p,
a,
span {
  font-family: "Roboto-Regular", serif, "Times New Roman", Times;
  font-weight: normal;
  letter-spacing: normal;
  font-size: 16px;
}

a {
  font-family: "Roboto-Regular", serif, "Times New Roman", Times;
  font-weight: normal;
  letter-spacing: normal;
  text-decoration: none;
  cursor: pointer;
  font-size: 18px;
}

.wrapper-pan {
  height: 100vh !important;
}

.left-menu {
  background-color: #4a7d9e;
  padding: 0;
}

/* Static Side nav added */
.static-nav {
  /* position: fixed; */
  /* position: absolute; */
  width: 210px;
  z-index: 1;
  /* height: 100%; */
}

.header-section {
  height: 50px;
}

.kartasoft-logo {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.client-logo-pan {
  height: 60px;
}

/* nav custom starts */
.left-menu .nav-item {
  margin: 5px 0;
}

.left-menu .nav-link {
  color: #ffffff;
  padding: 15px 0px 15px 8px;
  font-size: 18px;
  font-family: "Roboto-Regular";
  letter-spacing: 1px;
  line-height: 18px;
}

.left-menu .nav-link:hover {
  background: #2a4659;
}

.left-menu .nav-link i {
  margin-right: 6px;
}

.header-right-nav .nav-link {
  color: #4a7d9e;
}

.header-right-nav .nav-link:hover {
  color: #2a4659;
}

/* nav custom ends */

/* btn and Inputs */

.btn-primary {
  background-color: #4a7d9e;
  border-color: #4a7d9e;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:disabled {
  background-color: #2a4659;
  border-color: #2a4659;
  box-shadow: none;
}

input[type="text"] {
  height: 62px;
}

.map-preview input[type="text"],
.map-preview textarea {
  height: 50px;
}

input[type="color"] {
  width: 10rem;
}

label.form-label {
  font-size: 20px;
}

/* radio and checkbox custom styles */
.form-check {
  margin: 10px;
  height: 40px;
}

.form-check-input[type="radio"] {
  width: 25px;
  height: 25px;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input:checked {
  background-color: #4a7d9e;
  border-color: #2a4659;
}

.form-check-label {
  padding-left: 10px;
  vertical-align: middle;
  padding-top: 5px;
}

.form-check-input:focus,
.form-check-input[type="radio"]:hover {
  border-color: #2a4659;
}

/* input[type='checkbox'] {
    display: none;
} */

label.cust-checkbox-lable {
  display: flex;
  color: #666666;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin: 10px;
  font-size: 16px;
  line-height: 25px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
}

label.cust-checkbox-lable:before {
  line-height: 20px;
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: 10px;
  position: absolute;
  left: 0;
  top: -1px;
  border-radius: 1px;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
}

input[type="checkbox"]:checked + label.cust-checkbox-lable:before {
  content: "\2713";
  font-size: 21px;
  color: #ffffff;
  background-color: #4a7d9e;
  text-align: center;
  line-height: 21px;
  width: 25px;
  height: 25px;
  padding-left: 0;
}

label.cust-checkbox-lable:hover:before {
  color: #ffffff;
  text-shadow: none;
  /* background-color: #4a7d9e; */
  border-color: #2a4659;
}

.checkbox label img {
  height: 40px;
  padding-right: 10px;
}
/* radio and checkbox custom styles */

/* leaflet custom styles starts here */

.leaflet-popup-content h4.advice {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
  font-size: 16px;
  padding: 10px;
}

.leaflet-control-layers-expanded {
  width: 250px !important;
}

.leaflet-control-layers-overlays > label > div {
  display: flex;
}

.leaflet-control-layers-overlays > label > div > input[type="checkbox"] {
  display: block;
}

.leaflet-control-layers-overlays > label > div > span {
  margin-left: 10px;
}

.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 19px;
  left: 14px;
  width: 10px;
  border-top: 3px solid #000000;
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 6px;
  left: 5px;
  height: 13px;
  width: 13px;
  border-top: 2px solid #000000;
}

button.easy-button-button {
  padding: 4px 8px !important;
  border-width: 0 !important;
}

.leaflet-bottom.leaflet-right .easy-button-container {
  border-color: transparent;
}

#kartasoft-watermark {
  background: transparent;
  width: 45px;
  height: auto;
  display: block;
  padding: 0 !important;
}

#kartasoft-watermark span img {
  width: 100%;
  height: auto;
}

/* leaflet custom styles ends here */

/* ngx tabs style starts here */
#my_mpas .nav-link {
  cursor: pointer !important;
  color: #2a4659;
}

#my_mpas .tab-container .nav-pills .nav-link.active,
#my_mpas .nav-link:hover,
#my_mpas .nav-link.active {
  border-bottom: 4px solid #4a7d9e !important;
  border: none;
  color: #4a7d9e;
}

#my_mpas .nav-tabs {
  border-bottom: 0px solid #dee2e6;
  margin-bottom: 20px;
}

#my_mpas .nav-tabs .nav-link {
  border: none !important;
}

#my_mpas .tab-container .nav-pills .nav-link.active {
  background-color: transparent !important;
}

#my_mpas .nav-pills .nav-link,
#my_mpas .nav-pills .nav-link:hover {
  border: none;
  border-radius: 0;
}

/* ngx tabs styles ends here */

.cb-child {
  margin-left: 36px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-r-5 {
  margin-right: 5px;
}

/* image file upload */
.upload-img-group {
  width: fit-content;
  width: -moz-fit-content;
  border: 1px solid #bbbbbb;
  cursor: pointer;
}

#upload {
  opacity: 0;
}

#upload-label {
  position: absolute;
  top: 50%;
  left: 2rem;
  transform: translateY(-50%);
}

.image-area {
  border: 2px dashed #4a7d9e;
  padding: 1rem;
  position: relative;
  width: fit-content;
  width: -moz-fit-content;
  min-width: 200px;
}

.image-area::before {
  content: "Preview";
  color: 4a7d9e;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  z-index: 1;
}

.image-area img {
  z-index: 2;
  position: relative;
  height: 60px;
}

.upload-icon-append {
  background: #4a7d9e;
  color: #ffffff;
}

.upload-icon-append:hover {
  background: #2a4659;
  color: #ffffff;
}

/* ng select custom starts here */
.ng-select-container,
.ng-select-container input[type="text"] {
  height: 50px !important;
}

.ng-select-container input[type="text"] {
  height: unset !important;
}

.ng-option-child label.cust-checkbox-lable:before {
  margin-top: 0;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #4a7d9e;
  color: #ffffff;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  background-color: #4a7d9e !important;
  color: #ffffff !important;
}

.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 13px !important;
}

.ng-select-container .ng-value-container .ng-placeholder {
  color: #6c757d !important;
}
/* ng select custom ends here */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.copy-right a {
  color: #ffffff;
  font-size: 12px;
  text-align: center !important;
  bottom: 100px !important;
}

.copy-right a:hover {
  text-decoration: underline !important;
}

/* From Kartasoft Python Main.css */
#map-container {
  width: 100%;
  height: 100%;
  background-color: #dbdbdb;
  padding-top: 55px;
}
.bg-dark {
  background-color: #002664 !important;
}
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #fff;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #000000;
}
.leaflet-popup-content h4 {
  text-transform: none;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
  color: #ffffff;
  background-color: #002664;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #457fa3;
}

.leaflet-popup-content h4.advice {
  background-color: #d9edf7;
  border-color: #bcdff1;
  color: #31708f;
}

.leaflet-popup-content h4.watch {
  background-color: #fcf8e3;
  border-color: #faf2cc;
  color: #8a6d3b;
}
.leaflet-popup-content h4.emergency {
  background-color: #f2dede;
  border-color: #ebcccc;
  color: #a94442;
}
.leaflet-popup-content h4.Renewable {
  background-color: #98c73a;
  border-color: #faf2cc;
  color: #333333;
}

.leaflet-popup-content h5 {
  margin-bottom: 8px;
  line-height: 1.1;
}
.leaflet-popup-content dl {
  display: inline-block;
  min-width: 300px;
  margin-bottom: 10px;
}
.leaflet-popup-content dt {
  width: 30%;
  float: left;
}
.leaflet-popup-content dd {
  width: 70%;
  float: left;
}

.leaflet-popup-content dl.dates dt {
  font-size: 80%;
}
.leaflet-popup-content dl.dates dd {
  font-size: 80%;
}
.logo {
  cursor: pointer;
}

.disctab {
  margin: 15px 15px 15px 30px;
}
.disctab td {
  text-align: left;
  vertical-align: top;
  padding: 0px 5px 0px 0px;
}
.disctab img {
  width: 20px;
  height: auto;
  padding: 0px 5px 5px 0px;
}

.essential h2 {
  text-transform: none;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 1.1;
  color: #ffffff;
  background-color: #002664;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #457fa3;
}
.essential div {
  display: inline-block;
  font-weight: bold;
  vertical-align: top;
}
.essential span {
  font-weight: normal;
  padding: 0px 5px 3px 0px;
  display: inline-block;
  width: 130px;
  float: left;
}

.leaflet-bar button,
.leaflet-bar button:hover {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar button {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  overflow: hidden;
  display: block;
}

.leaflet-bar button:hover {
  background-color: #f4f4f4;
}

.leaflet-bar button:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar button:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar.disabled,
.leaflet-bar button.disabled {
  cursor: default;
  pointer-events: none;
  opacity: 0.4;
}

.easy-button-button .button-state {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.leaflet-touch .leaflet-bar button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

@media only screen and (max-width: 500px) {
  .leaflet-popup-content {
    max-width: 220px;
  }
  .leaflet-popup-content dl {
    min-width: auto;
  }
}
.ship-photo {
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}
.ship-photo img {
  width: 100%;
  height: auto;
}
.info-box {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
table.pws {
  width: 100%;
}
table.pws td {
  vertical-align: top;
}

table.pwd {
    border-collapse: collapse;
    width: 100%;
    margin-top: 5px;
}

table.pwd th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
    padding: 8px;
}

table.pwd th, table.pwd td {
    border: 1px solid #ddd;
    padding: 8px;
}

/* Add hover effect on table rows */
table.pwd tbody tr:hover {
    background-color: #f5f5f5;
}

/* Style the header row separately */
table.pwd thead tr {
    background-color: #333;
    color: #000;
}

table.pwd thead th {
    border: 1px solid #333;
}

#menuFlyout-outer {
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  float: right;
  height: 100%;
  top: 60px;
  right: -305px;
  position: fixed;
  z-index: 1001;
  padding-top: 15px;
  box-shadow: 2px 2px 3px rgba(33, 33, 33, 0.7);
  background-color: #eeeeee;
  opacity: 0.95;
}

.nav-background {
  background-color: #ffffff;
}

#btnMenuToggle {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 99999;
  color: #dbdbdb;
  font-size: 36px;
}

.navbar-toggler {
  margin-right: 25px;
}

.filter-outer {
  margin-top: 40px;
  background-color: #eeeeee;
}

.filter-container {
  display: inline-block;
  margin: 0px 0px 15px 25px;
  background-color: #eeeeee;
  padding: 0px 15px 0px 0px;
}

.filter-group {
  clear: both;
}

.filter-group-title {
  font-size: 14px;
  font-weight: normal;
  padding: 0px 0px 0px 15px;
}

.filter-row {
  clear: both;
  width: 270px;
  padding: 0px 15px 0px 5px;
  box-sizing: border-box;
  display: inline-block;
}

.filter-check {
  width: 30px;
  float: left;
  padding-left: 15px;
  display: inline-block;
  padding-top: 5px;
}

.filter-image {
  width: 25px;
  float: left;
  display: inline-block;
  padding-left: 5px;
}

.filter-image img {
  width: 15px;
  height: auto;
}

.filter-text {
  width: 150px;
  float: left;
  display: inline-block;
  font-weight: normal;
  padding-top: 5px;
}

/*Accordion*/
.fliter-container .panel-group {
  margin: 0px 10px 0px 25px;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none !important;
}

#filter-accordion .panel-group .panel {
  background-color: #fff;
  border: none;
  box-shadow: none;
  border-radius: 10px;
  margin-bottom: 11px;
}

#filter-accordion .panel .panel-heading {
  padding: 0;
  border-radius: 10px;
  border: none;
  width: 270px;
}

#filter-accordion .panel-heading a {
  color: #fff !important;
  display: block;
  border: none;
  padding: 15px 35px 15px;
  font-size: 18px;
  background-color: #002664;
  font-weight: 600;
  position: relative;
  color: #fff;
  box-shadow: none;
  transition: all 0.1s ease 0;
}

#filter-accordion .panel-heading a:after,
#filter-accordion .panel-heading a.collapsed:after {
  content: "\f068";
  font-family: fontawesome;
  text-align: center;
  position: absolute;
  left: -20px;
  top: 5px;
  color: #fff;
  background-color: #002664;
  border: 5px solid #fff;
  font-size: 15px;
  width: 40px;
  height: 40px;
  line-height: 30px;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

#filter-accordion .panel-heading:hover a:after,
#filter-accordion .panel-heading:hover a.collapsed:after {
  transform: rotate(360deg);
}

#filter-accordion h4 {
  margin-bottom: 5px;
}

#filter-accordion .panel-heading a.collapsed:after {
  content: "\f067";
}

#filter-accordion .panel-body {
  background-color: #eeeeee;
  color: #555555;
  line-height: 25px;
  padding: 10px 15px 20px 15px;
  border: 1px solid #002664;
  font-size: 14px;
  position: relative;
  width: 270px;
  margin-bottom: 5px;
}

/* .leaflet-popup-close-button{
    display: none;
} */

.leaflet-popup-content-wrapper {
  max-height: 250px;
  max-width: 400px;
  overflow-y: auto !important;
}

/* For Hovering Issue */
/* body{
    overflow: hidden;
} */

.msg-error {
  color: #d43535;
}

#recent-outages .btn-link {
  font-size: 16px !important;
  color: #45494b !important;
}

#recent-outages .btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

#recent-outages .accordion-button:not(.collapsed)::after {
  background-image: none !important;
}

#recent-outages .accordion-button:not(.collapsed) {
  color: #45494b !important;
  background-color: #fcfcfc;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
  cursor: pointer;
}

.mloutages-20 {
  margin-left: 30px;
}

.unplanned-o {
  color: #6c757d !important;
}

.planned-o {
  color: #0c63e4 !important;
}

.reported-o {
  color: #dc3545 !important;
}

.opened-accordian {
  background-color: #dfe2e6 !important;
}

.leaflet-radar {
  /* from leaflet-control-layers */
  border-radius: 5px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;

  padding: 5px;
  height: 75px;
}

.leaflet-radar .leaflet-radar-timestamp {
  text-align: center;
}

.leaflet-radar-toggle span {
  margin-left: 4px;
}
